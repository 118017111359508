/* eslint-disable indent */
import { html, css } from 'Elements';
import { Fetcher, UrlParams, Session, Sleep } from 'Utils';
import { Router } from 'Router';
import App from 'App';

import Step from '../Step.js';
import dayjs from 'dayjs';

import './loading.js';
import './header.js';
import './title.js';
import './buttons_soc.js';
import './tlp.js';
import './warning_closed.js';
import './warning_taken.js';
import './block_resume.js';
import './block_infos_declarant.js';
import './block_infos_declaration.js';
import './block_horodatage.js';
import './block_analyste.js';
import './block_evaluation.js';
import './block_description.js';
import './block_actifs.js';
import './block_impacts.js';
import './block_resultats.js';
import './block_contremesures.js';
import './block_infos_more.js';
import './block_print.js';

class Consulter extends Step {
  static get styles() {
    return [
      super.styles,
      css`
        .container {
          margin:5px;
        }

        .csirt {
          font-size:2em;
          margin:20px;
          text-align:center;
        }

        div svg path {
          stroke: #000000;
          stroke-width: 3px;
        }

        .header {
          display:flex;
        }

        m-icon[name="edit"] {
          float:right;
        }

        m-icon[name="bug_report"] {
          font-size:80px;
          margin-bottom:50px;
        }

        .error_container {
          padding:20px;
          text-align:center;
        }

        .error_container m-icon {
          display:block;
          text-align:center;
          font-size:60px;
          color:var(--sl-color-danger-500);
        }

        sl-button[hide] {
          display:none; 
        }

        .block_error {
          display:none;
        }

        [anchor] {
          display:block;
          padding:5px;
          border-radius:5px;
          transition:background-color 0.5s;
          page-break-inside: avoid;
          margin-bottom:20px;
        }

        incident-title {
          margin-top:20px;
        }

        [anchor].highlight {
          background-color:var(--sl-color-neutral-0);
          transition:background-color 0.2s;
        }

        @media print {
          .noprint {
            display:none;
          }

          .content, .background, .item, .highlight {
            background:transparent !important;
            background-color:transparent !important;
          }

          /*
          [anchor] {
            background-color: rgba(255, 255, 255, 0.8) !important; 
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
          }
          */
        }

      `
    ];
  }

  static get properties() {
    return {
      showLoadingDialogError: { type: Boolean },
      ticketLoaded: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.showLoadingDialogError = false;
    this.ticketLoaded = false;
    this.errorInvalidTicket = false;
    this.observer = null;
    this.takeTicket = this.takeTicket.bind(this);
    this.toIncident = this.toIncident.bind(this);
    this.toFalsePositive = this.toFalsePositive.bind(this);
    this.handleTicketAction = this.handleTicketAction.bind(this);
    this.initIntersectionObserver = this.initIntersectionObserver.bind(this);
    this.handleIntersect = this.handleIntersect.bind(this);

  }

  async connectedCallback() {
    super.connectedCallback();
    this.who = UrlParams.get('who', 'sysdream');
    this.urlWithoutHash = window.location.href.split('#')[0];
  }

  async disconnectedCallback() {
    super.disconnectedCallback();
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  initIntersectionObserver() {
    clearTimeout(this.observerTimeout);
    this.observerTimeout = setTimeout(() => {
      const options = {
        root: null, // window
        rootMargin: '-50% 0% -50% 0%',
        threshold: 0 // Adjust this threshold as needed
      };

      this.observer = new IntersectionObserver(this.handleIntersect.bind(this), options);

      const anchors = this.shadowRoot.querySelectorAll('.container [anchor]');
      anchors.forEach(anchor => this.observer.observe(anchor));
      this.goToAnchor();
    }, 200);
  }

  handleIntersect(entries) {
    let firstVisibleAnchorFound = false;
    let anchor;
    let el;
    entries.forEach(entry => {
      if (entry.isIntersecting && !firstVisibleAnchorFound) {
        anchor = entry.target.getAttribute('anchor');
        el = entry.target;         
        firstVisibleAnchorFound = true;
      }
    });

    if (anchor && this.previousAnchor !== anchor) {
      this.previousAnchor = anchor;
      if (this.previousAnchorEl) {
        this.previousAnchorEl.classList.remove('highlight');
      }
      this.previousAnchorEl = el;
      el.classList.add('highlight');

      const url = this.urlWithoutHash + '#' + anchor;
      history.pushState(null, null, url);
    }
  }

  async goToAnchor() {
    const anchor = window.location.hash.replace('#', '');
    if (!anchor) return;
    const el = this.shadowRoot.querySelector(`[anchor="${anchor}"]`);
    if (!el) return;
    //el.scrollIntoView(  { behavior: 'smooth', block: 'center' });
    await Sleep(100);
    // @FIXME: probably because scrollable-component, we can't use this
    //el.scrollIntoView({ behavior: 'auto', block: 'start' });
  }

  async firstUpdated() {
    super.firstUpdated();

    const dialog = this.shadowRoot.querySelector('#loading_dialog');
    if (dialog) {
      dialog.addEventListener('sl-request-close', event => {
        if (event.detail.source === 'overlay') {
          event.preventDefault();
        }
      });
    }

    while (!App.config.router?.location?.params?.hash) {
      await Sleep(100);
    }

    this.hash = App.config.router.location.params.hash;
    let response;
    try {
      response = await Fetcher.post('public/rt/ticket/view', { hash:this.hash });
    } catch(e) {
      console.error(e);
      this.showLoadingDialogError = true;
      return;
    }

    if (!response || !response.data) {
      this.showLoadingDialogError = true;
      return;
    }

    this.ticket = response.data;
    this.ticketLoaded = true;
    this.errorInvalidTicket = false;
    /*
    console.log(response.data);
    console.log(response.data2);
    for (const customField of response.data2.CustomFields) {
      console.log(customField.id, customField.name, customField.values);
    }
    */

    if (this.ticket) {
      setTimeout(async () => {
        if (this.isIncident()) {
          document.title = `Incident n°${this.ticket.id} - CSIRT SysDream`;
        } else {
          document.title = `Alerte n°${this.ticket.id} - CSIRT SysDream`;
        }

        this.initIntersectionObserver();
      }, 100);
    } else {

        setTimeout(() => {
          if (response.error === 'ETICKET_INVALID_HASH') {
            this.errorInvalidTicket = true;
            this.requestUpdate();
            return;
          }

          if (response.error === 'ETICKET_NOTFOUND') {
            this.errorInvalidTicket = true;
            this.requestUpdate();
            return;
          }

          //this.shadowRoot.querySelector('.error_message').innerHTML = 'Erreur inconnue.';
        }, 100);
    }
  }

  isInvalidTicket() {
    return this.errorInvalidTicket;
  }

  closeDialog() {
    this.showLoadingDialogError = false;
    Router.go('/public');
  }

  formatDate(v) {
    if (!v) return 'N/A';
    if (v === '1970-01-01T00:00:00Z') return 'N/A';
    return dayjs(v).format('DD/MM/YYYY HH:mm');
  }

  getAnalysteInfo(field) {
    if (!this.ticket) return 'N/A';
    //console.log(this.ticket.owner);
    const owner = this.ticket.owner[field];
    if (!owner) return 'N/A';
    if (owner === 'Nobody in particular') return 'En attente de prise en charge';
    return owner;
  }

  isEditing() {
    return window.location.pathname.includes('edit');
  }

  isIncident() {
    return this.ticket.status === 'open';
  }

  isFalsePositive() {
    return this.ticket.classificationText.includes('FP - Faux Positif');
  }

  isResolved() {
    return this.ticket.status === 'resolved';
  }

  isNew() {
    return this.ticket.status === 'new';
  }

  getCounterMeasuresCount() {
    if (!this.ticket.countermeasures) return 1;
    if (!this.ticket.countermeasures.length) return 1;
    return this.ticket.countermeasures.length+1;
  }

  async reloadTicket() {
    this.ticketLoaded = false;
    this.ticket = null;

    let response;
    try {
      response = await Fetcher.post('public/rt/ticket/view', { hash:this.hash });
      console.log(response);
    } catch(e) {
      console.error(e);
      this.showLoadingDialogError = true;
      return;
    }

    if (!response) {
      this.showLoadingDialogError = true;
      return;
    }

    this.ticket = response.data;
    this.ticketLoaded = true;
    this.showLoadingDialog = false;
  }

  isTicketTakenByMe() {
    console.log(`isTicketTakenByMe: ticket email: ${this.ticket.owner.email}, session=${Session.session.email}`);
    return this.ticket.owner.email === Session.session.email;
  }

  isTicketTaken() {
    console.log(`isTicketTaken: ticket email: ${this.ticket.owner.email}`);
    return this.ticket.owner.email;
  }

  async handleTicketAction(ev, url) {
    const modalDialog = ev.target.closest('modal-dialog');   
    const btCancel = modalDialog.querySelector('sl-button[variant="text"]');
    const btSubmit = modalDialog.querySelector('sl-button[variant="primary"]');
    
    btSubmit.loading = true;
    btCancel.disabled = true;

    try {
      const response = await Fetcher.post(url);
      if (!response || !response.ok) {  
        btSubmit.loading = false;
        btCancel.disabled = false;
        modalDialog.hide();
        return;
      }

      await Sleep(3000);
      await this.reloadTicket();

      btSubmit.loading = false;
      btCancel.disabled = false;
      modalDialog.hide();      
      
    } catch(e) {
      console.error(e);
      return;
    }
  }

  async takeTicket(ev) {
    await this.handleTicketAction(ev, `private/soc/alert/${this.hash}/to/taken`);

    while (!this.isTicketTakenByMe()) {
      await Sleep(2000);
      await this.reloadTicket();
    }

    this.requestUpdate();
    this.initIntersectionObserver();
  }

  async toIncident(ev) {
    await this.handleTicketAction(ev, `private/soc/alert/${this.hash}/to/incident`);

    while (!this.isIncident()) {
      await Sleep(2000);
      await this.reloadTicket();
    }

    this.requestUpdate();
    this.initIntersectionObserver();

  }

  async toFalsePositive(ev) {
    await this.handleTicketAction(ev, `private/soc/alert/${this.hash}/to/falsepositive`);

    while (!this.isFalsePositive()) {
      await Sleep(2000);
      await this.reloadTicket();
    }

    this.requestUpdate();
    this.initIntersectionObserver();
  }

  render() {
    
    //if (this.ticket) console.log(this.ticket);

    return html`
      <div class="noprint">
        <section-header>
          <img slot="left" src="assets/img/dossier.png" height="30"/>
          Traitement des alertes et incidents
          <tlp-level slot="right" name="red"></tlp-level>
        </section-header>
      </div>
      ${this.ticket ? html`
        <div class="content_page nomargin">
          <div class="item">
            <div class="container">
              <warning-closed .parent=${this}></warning-closed>
              <warning-taken .parent=${this}></warning-taken>
              <incident-buttons-soc .parent=${this}></incident-buttons-soc>
              <incident-header .parent=${this}></incident-header>
              <incident-title .parent=${this}></incident-title>
              <incident-block-resume anchor="resume" .parent=${this}></incident-block-resume>
              <incident-block-infos-declarant anchor="iwho" .parent=${this}></incident-block-infos-declarant><br/>
              <incident-block-infos-declaration anchor="idec" .parent=${this}></incident-block-infos-declaration>
              <incident-block-horodatage anchor="time" .parent=${this}></incident-block-horodatage><br/>
              <incident-block-analyste anchor="analyst" .parent=${this}></incident-block-analyste>
              <incident-block-evaluation anchor="eval"  .parent=${this}></incident-block-evaluation>
              <incident-block-description anchor="desc" .parent=${this}></incident-block-description>
              <incident-block-actifs anchor="box" .parent=${this}></incident-block-actifs>
              <incident-block-impacts anchor="boom" .parent=${this}></incident-block-impacts>
              <incident-block-resultats anchor="result" .parent=${this}></incident-block-resultats>
              <incident-block-contre-mesures anchor="countermeasures" .parent=${this}></incident-block-contre-mesures>
              <incident-block-infos-more anchor="more" .parent=${this}></incident-block-infos-more>
              <incident-block-print .parent=${this}></incident-block-print>
            </div>
          </div>
        </div>
        <br/>
          `: this.isInvalidTicket() 
            ? html`
              <div class="content">
                <div class="item">
                  <h3><m-icon name="error"></m-icon>Une erreur est survenue</h3>
                  <hr-custom></hr-custom>
                  <div class="error_container">
                    <br/>
                    <br/>
                    <m-icon name="scan_delete"></m-icon>
                    <p>Votre dossier est introuvable</p>
                    <br/>
                  </div>
                </div>
              </div>`
            : html`
              <div class="content" style="display:${this.showLoadingDialogError ? 'none': 'block'}">
                <incident-loading></incident-loading>
              </div>
              <div class="content" style="display:${this.showLoadingDialogError ? 'block': 'none'}">
                <div class="item">
                  <p class="center">
                    <br/><br/><br/>
                    <m-icon name="bug_report" nogradient></m-icon>
                    <br/>
                    ${this.errorMessage || 'Une erreur est survenue. Merci de réessayer ultérieurement.'}
                  </p>
                  <br/>
                </div>
              </div>
        `
      }
    `;
  }


}

customElements.define('page-dossier-consulter', Consulter);

