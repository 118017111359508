import { BaseElement, html, css } from 'Elements';
import { Session } from 'Utils';
import styles from './styles.js';

class Part extends BaseElement {
  static get styles() {
    return [
      styles,
      css`

        .img_icons {
          height:22px;
        }

        .margin {
          position:relative;
        }

        .ticket_number {
          padding:20px;
          text-align:center;
          font-size:25px;
          font-weight:600;
          text-transform:uppercase;
          color:var(--nsys-blue3);
        }

        .number {
          font-size:30px;
          line-height:30px;
          margin-left:5px;
          padding-left:4px;
          padding-right:4px;
        }

        a-route {
          position:absolute;
          top:5px;
          right:20px;
        }

        a-route m-icon {
          color:red;
        }

        @media print {
          a-route {
            display:none;
          }
        }

      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  constructor() {
    super();
    this.parent = null;
  }

  getAlertTitle() {
    if (this.parent.isNew()) return 'Alerte';
    if (this.parent.isFalsePositive()) return 'Alerte';
    return "Incident"
  }

  render() {
    return html`
      <div class="margin">
        <section-header><img slot="left" src="assets/img/incident.png" class="img_icons"/>${this.getAlertTitle()} n°<span class="number">${this.parent.ticket.id}</span></section-header>
        <br/>
        ${
          Session.isSoc() 
            ? this.parent.isEditing()
              ? html`<a-route class="noprint" href="/public/mondossier/consulter/${this.parent.hash}"><m-icon name="visibility" nogradient title="Vue client"></m-icon></a-route>`
              : html`<a-route class="noprint" href="/private/soc/alert/${this.parent.hash}/edit"><m-icon name="edit" nogradient title="Passer en mode édition (Admins & SOC Only)"></m-icon></a-route>`
            : ''
        }
      </div>
    `;
  }

}

customElements.define('incident-title', Part);