import { css } from 'lit-element';

export default css`
  :host {
    display:block;
  }

  .line, .lineh {
    display:flex;
    justify-content:space-between;
    margin-left:10px;
    margin-right:10px;
  }

  .lineh div {
    width:100%;
  }

  .lineh div:first-child {
    width:300px;
  }

  .line div {
    width:100%;
  }

  .line2 {
    display:flex;
  }

  .line2 div {
    width:300px;
  }

  .bold {
    font-weight:bold;
  }

  .bold.red {
    color:red;
  }

  .flex {
    display:flex;
    justify-content:space-between;
    gap:20px;
  }

  .flex2 {
    display:flex;
    justify-content:space-between;
    gap:20px;
  }

  @media print {
    .noprint {
      display:none;
    }
  }
`;
