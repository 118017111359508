import { BaseElement, html, css } from 'Elements';

class Part extends BaseElement {
  static get styles() {
    return [
      css`
        .warning m-icon {
          font-size: 30px;
          float: left;
          margin-right: 10px;
        }

        @media print {
          :host {
            display:none;
          }
        }
      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  constructor() {
    super();
    this.parent = null;
  }


  render() {
    if (!this.parent.ticket) return;
    if (!this.parent.isEditing()) return;
    if (!this.parent.isTicketTaken()) return;
    if (this.parent.isTicketTakenByMe()) return;

    return html`
      <box-styled variant="warning" micon="warning" layout="row">Cette alerte est actuellement en cours d'investigation par ${this.parent.getAnalysteInfo('realName')}.</box-styled>
    `;
  }

}

customElements.define('warning-taken', Part);